import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./App.css";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Skills from "./components/skills/Skills";
// import Services from "./components/services/Services";
import Qualification from "./components/qualifications/Qualification";
// import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";
import Projects from "./components/projects/Projects";
import Loading from "./components/loading/Loading";

const App = () => {
    // const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 3000);
    // }, []);

    // if (isLoading) {
    //     return <Loading />;
    // }

    return (
        <HelmetProvider>
            <Helmet>
                <title>Shakila Kamalasena</title>
                <meta name="title" content="Shakila Kamalasena" />
                <meta
                    name="description"
                    content="Balancing coding and my personal passions. Committed to continuous learning while enjoying the best of both worlds."
                />
                <meta
                    name="keywords"
                    content="Shakila, Kamalasena, Shakila Kamalasena, doinkcozyy"
                />
                <link rel="canonical" href="https://shakilakamalasena.me" />
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Person",
                            "name": "Shakila Kamalasena",
                            "description": "Balancing coding and my personal passions. Committed to continuous learning while enjoying the best of both worlds.",
                            "url": "https://www.shakilakamalasena.me",
                            "sameAs": [
                                "https://www.linkedin.com/in/shakila-kamalasena-720038103/",
                                "https://github.com/shakilakamalasena",
                                "https://www.instagram.com/doinkcozyy/",
                                "https://www.facebook.com/shakila.kamalasena",
                                "https://twitter.com/doinkcozyy"
                            ],
                            "jobTitle": [
                                "Software Engineer"
                            ],
                            "alumniOf": "University of Ruhuna",
                            "birthDate": "2000-11-27",
                            "gender": "Male",
                            "image": "https://www.shakilakamalasena.me/static/media/About2.736d012442fc0545a205.jpg"
                        }
                    `}
                </script>
            </Helmet>

            <Header />

            <main className="main">
                <Home />
                <About />
                <Skills />
                {/* <Services /> */}
                <Qualification />
                {/* <Testimonials /> */}
                <Projects />
                <Contact />
            </main>
            <Footer />
            <ScrollUp />
        </HelmetProvider>
    );
};

export default App;
